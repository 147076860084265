<template>
  <div class="home">
    <pc v-if="show"/>
    <wap v-else />
  </div>
</template>

<script>
import pc from "../components/pc_index.vue";
import wap from "../components/wap.vue";
export default {
  name: "HomeView",
  components: {
    pc,
    wap,
  },
  data(){
    return{
      show:true
    }
  },
  created() {
    this.$store.dispatch('initActions')
    this.handleResize()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize(){
      let width = window.innerWidth || document.clientWidth
     
      if( width<=750){
        this.show = false
      }else{
        this.show = true
      }
      
    },
  },
};
</script>
