import { render, staticRenderFns } from "./rankGame.vue?vue&type=template&id=425317ce&scoped=true"
import script from "./rankGame.vue?vue&type=script&lang=js"
export * from "./rankGame.vue?vue&type=script&lang=js"
import style0 from "./rankGame.vue?vue&type=style&index=0&id=425317ce&prod&lang=css"
import style1 from "./rankGame.vue?vue&type=style&index=1&id=425317ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "425317ce",
  null
  
)

export default component.exports