import axios from 'axios'
import { tansParams } from "@/utils/index";
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
export function createBackend(baseURL) {
  // 创建axios实例
  const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: baseURL,
    // 超时
    timeout: 10000
  })
  // request拦截器
  service.interceptors.request.use(config => {
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }

    return config
  }, error => {
    console.log(error)
    Promise.reject(error)
  })

  // 响应拦截器
  service.interceptors.response.use(res => {

    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data
    }
    return res.data

  },
    error => {

      return Promise.reject(error)
    }
  )
  return service;
}

