<template>
  <div id="newCenter" class="newCenter_box">
    <div class="new_title">资讯中心</div>
    <div class="new_center_box">
      <div class="new_menu">
        <div class="new_menu_ul">
          <div v-for="(item, index) in menuList" :key="index" @click="changeNav(index)" class="menu_item"
            :class="indexs == index ? 'active' : ''">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="swiper newCenter_swiper1">
        <div class="swiper-wrapper">
          <div class="new_content swiper swiper-slide stop-swiping" v-for="(item, index) in menuList" :key="index"
            :class="`wgame_swiper_public${index}`">
            <div class="swiper-wrapper">
              <div class="wap_game_single_special swiper-slide" v-for="(each, eachIndex) in item.list" :key="eachIndex">
                <div class="item_slide" v-for="(present, presentIndex) in each" :key="presentIndex"
                  @click="tolink(present)">
                  <div class="left">
                    <img :src="present.phone_img" alt />
                  </div>
                  <div class="right">
                    <div>{{ present.name }}</div>
                    <div>{{ present.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!item.list || item.list.length === 0">
              <div class="empty_img">
                <img src="../../assets/image/wap/banner/709ca4f38368be34aa413e73e2550417743d8a00fb07-yydiFy.png" alt />
              </div>
              <div class="empty_tex">暂无内容</div>
            </div>
            <!-- v-if="item.list.length > 1" -->
            <div class="swiper-pagination" ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { customText } from '@/utils/key'
import { downloadA } from "@/utils/download";
import { officeArticle } from "@/api/init";
import { convertTo2DArray } from "@/utils/index";
export default {
  data() {
    return {
      indexs: 0,
      isShow: false,
      menuList: [
        {
          title: "全部资讯",
          show: true,
          list: [],
        },
        {
          title: "公告",
          show: false,
          list: [],
        },
        {
          title: "攻略",
          show: false,
          list: [],
        },
        {
          title: "活动",
          show: false,
          list: [],
        },
      ],
      queryList: [
        { cate: '' },
        { cate: 1 },
        { cate: 2 },
        { cate: 3 },
      ],
      swiperRecord: [],
      gameSwiperList:[]
    };
  },
  created() { },
  mounted() {
    this.getList();
    this.initSwiper();
  },
  methods: {
    tolink(item) {
      switch (item.type) {
        case 1:
          downloadA(item.url)
          break;
        case 2:
          if (item.content) {
            localStorage.setItem(customText, item.content);
            let href = window.location.href
            window.open(`${href}custom`)
          }
          break;
        case 3:
          if (item.url) {
            this.$router.push({
              path: item.url,
            })
          }
          break;
        default:
          break;
      }
    },
    async getList() {
      let { data, code } = await officeArticle(this.queryList[this.indexs]);
      this.menuList[this.indexs].list = convertTo2DArray(data, 4)
      this.$nextTick(() => {
        this.tabSwiper()
      })
    },
    changeNav(index) {
      this.indexs = index;
      this.menuList.forEach((item) => {
        item.show = false;
      });
      this.menuList[index].show = true;
      this.gameSwiper.slideToLoop(index, 1000, false);
      this.$nextTick(() => {
        this.getList()
      })
    },
    tabSwiper() {
      if (!this.swiperRecord.includes(this.indexs)) {
        this.swiperRecord.push(this.indexs)
        let swiper = new Swiper(`.wgame_swiper_public${this.indexs}`, {
          centeredSlides: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: "my-bullet1",
            clickableClass: "my-pagination-clickable",
          },
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            touchEnd: function (swiper, event) {
              // console.log("出发蓝");
            },
          },
        });

        this.gameSwiperList.push(swiper);
      }
    },
    // 初始化swiper
    initSwiper() {
      this.gameSwiper = new Swiper(".newCenter_swiper1", {
        centeredSlides: true,
        // initialSlide: index,
        noSwiping: true,
        noSwipingClass: "stop-swiping",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });
    },
  },
};
</script>

<style scoped lang="scss">
.newCenter_box {
  width: 100%;
  height: 100%;
  // background-color: palegoldenrod;
  background: url(../../assets/image/wap/bg2/1.png) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  .new_title {
    font-size: 0.533333rem
      /* 20/37.5 */
    ;
    color: #393333;
    font-weight: bold;
    padding-top: 1.83333rem
      /* 20/37.5 */
    ;
    padding-bottom: 0.533333rem;
  }

  //内容
  .new_content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.533333rem
      /* 20/37.5 */
    ;
    flex-direction: column;
  }

  .new_center_box {
    width: 100%;
    height: 100%;
    // background: #ffffff;

    // margin-top: 0.586667rem /* 22/37.5 */;
    box-sizing: border-box;

    .new_menu {
      height: 0.746667rem
        /* 28/37.5 */
      ;
      width: 9.466667rem
        /* 355/37.5 */
      ;
      border-bottom: 0.053333rem
        /* 2/37.5 */
        solid #949494;
      text-align: center;
      line-height: 0.375rem;
      color: #000000;
      //   font-weight: bold;
      font-size: 0.426667rem
        /* 16/37.5 */
      ;
      margin: 0 auto;

      .new_menu_ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-top: 0.161458rem
          /* 31/192 */
        ;

        .menu_item {
          //   margin-left: 0.641667rem;
          cursor: pointer;
        }

        .active {
          // color: red;
          position: relative;
        }

        .active::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -0.42rem;
          width: 100%;
          height: 0.053333rem
            /* 2/37.5 */
          ;
          background: #e14b4b;
          border-radius: 0.026667rem;
          /* 1/37.5 */
        }
      }
    }

    .wap_game_single_special {
      display: flex;
      margin-bottom: 0.533333rem
        /* 20/37.5 */
      ;
      flex-direction: column;
      align-items: center;
    }

    .item_slide {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.45rem
        /* 31/192 */
      ;
    }

    .left {
      width: 2.986667rem
        /* 112/37.5 */
      ;
      height: 1.68rem
        /* 63/37.5 */
      ;
      border-radius: 0.106667rem
        /* 4/37.5 */
      ;
      margin-right: 0.346667rem
        /* 13/37.5 */
      ;

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 0.2rem
          /* 28/16 */
        ;
      }
    }

    .right {
      width: 5.866667rem
        /* 220/37.5 */
      ;
    }

    .right>div:nth-child(1) {
      color: #393333;
      font-size: 0.373333rem
        /* 14/37.5 */
      ;
      padding-bottom: 0.07rem
        /* 10/37.5 */
      ;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
    }

    .right>div:nth-child(2) {
      color: #393333;
      font-size: 0.373333rem
        /* 14/37.5 */
      ;
      height: 1.013333rem
        /* 38/37.5 */
      ;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .empty_img {
      width: 6.346667rem
        /* 238/37.5 */
      ;

      height: 6.346667rem
        /* 238/37.5 */
      ;

      position: relative;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .empty_tex {
      text-align: center;
      color: #949494;

      font-size: 0.533333rem
        /* 20/37.5 */
      ;
    }
  }
}

// swiper
.newCenter_swiper {
  flex: 1;
  width: 100%;
  box-sizing: border-box;

  .new_content_box {
    width: 100%;
    height: 100%;
  }
}

.empty_tex {
  text-align: center;
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #949494;
}
</style>
<style>
.newCenter_swiper1 .my-bullet1+.my-bullet1 {
  margin-left: 0.2rem;
}

.newCenter_swiper1 .my-bullet1 {
  display: inline-block;
  width: 0.2rem
    /* 9/192 */
  ;
  height: 0.2rem
    /* 9/192 */
  ;
  border-radius: 50%;
  background-color: #fffefe !important;
}

.newCenter_swiper1 .swiper-pagination-bullet-active {
  background-color: #cc5341 !important;
}

.newCenter_swiper1 .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0.15rem;
  left: 2%;
}
</style>
