/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
      const value = params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && value !== "" && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
              let params = propName + '[' + key + ']';
              var subPart = encodeURIComponent(params) + "=";
              result += subPart + encodeURIComponent(value[key]) + "&";
            }
          }
        } else {
          result += part + encodeURIComponent(value) + "&";
        }
      }
    }
    return result
  }
  export function initUrl(params) {
    // 动态插入脚本标签
    const initUrl = process.env.VUE_APP_INIT;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = initUrl;
    document.head.appendChild(script);
  }
  /**
 * 将一维数组转换为二维数组
 * 此函数主要用于将给定的一维数组按照指定的步长分割成二维数组
 * 
 * @param {Array} arr - 需要转换的一维数组
 * @param {number} extent - 每个子数组的长度，也是分割的步长
 * @returns {Array} - 转换后的二维数组
 */
export function convertTo2DArray(arr,extent=4) {
  const result = [];
  for (let i = 0; i < arr.length; i += extent) {
    result.push(arr.slice(i, i + extent));
  }
  return result;
}
/**
 * 将二维数组切分为两个部分
 * @param {Array} arr - 需要被切分的二维数组
 * @returns {Array[]} - 返回一个数组，其中包含两个元素，分别是切分后的两个部分
 */
export function slicing2DArray(arr) {
  const length = arr.length;
  const half = Math.ceil(length / 2);
  const firstHalf = arr.slice(0, half);
  const secondHalf = arr.slice(half);
  return [firstHalf, secondHalf];
}