<template>
  <div class="rankGame_box" id="rankGame">
    <div class="rankGame_title">游戏排行</div>
    <div class="rankGame_content">
      <div class="nav_box">
        <div class="nav_item" v-for="(item, index) in rankgameList" :key="index" :class="{ active: index == indexs }"
          @click="activateNav(index)">
          <div class="nav_img">
            <img :src="require(index == indexs
              ? '../../assets/image/wap/icon/20.png'
              : '../../assets/image/wap/icon/14.png')
              " alt />
          </div>
          <div>{{ item.title }}</div>
        </div>
      </div>
      <div class="swiper mySwiper5">
        <div class="swiper-wrapper content_box">
          <div class="swiper swiper-slide stop-swiping swiper_box" v-for="(item, index) in rankgameList" :key="index"
            :class="`each_swiper${index}`">
            <!-- 对应导航模块下面的list -->
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(each, eachIndex) in item.content" :key="eachIndex">
                <!-- contetn下面的每一个对象 -->
                <div v-for="(present, presentIndex) in each" :key="presentIndex" class="item_box"
                  @click="tolink(present)">
                  <div class="content_img">
                      <img :src="present.phone_img" alt="" />
                  </div>
                  <div class="head">
                    <p class="title">{{ present.name }}</p>
                    <p class="tj">推荐指数:{{ present.rank_num }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <!-- <div class="swiper-button-next"></div>
             <div class="swiper-button-prev"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { customText } from '@/utils/key'
import { downloadA } from "@/utils/download";
import { engineGame } from "@/api/init";
import { convertTo2DArray } from "@/utils/index";
export default {
  data() {
    return {
      indexs: 0,
      isShow: false,
      rankgameList: [
        {
          title: "推荐榜",
          show: true,
          content: [],
        },
        {
          title: "新游榜",
          show: false,
          content: [ ],
        },
        {
          title: "人气榜",
          show: false,
          content: [],
        },
      ],
      queryList: [
        { rank: 1 },
        { rank: 2 },
        { rank: 3 },
      ],
      swiperRecord: [],
      rankSwiperList:[]
    };
  },
  created() { },
  mounted() {
    this.$nextTick(() => {
      this.getList()
      this.rankSwiper();
    });
  },
  methods: {
    tolink(item) {
      switch (item.type) {
        case 1:
          downloadA(item.url)
          break;
        case 2:
          if (item.content) {
            localStorage.setItem(customText, item.content);
            let href = window.location.href
            window.open(`${href}custom`)
          }
          break;
        case 3:
          if (item.url) {
            this.$router.push({
              path: item.url,
            })
          }
          break;
        default:
          break;
      }
    },
    async getList() {
      let { data, code } = await engineGame({ ...this.queryList[this.indexs], scene: 3 });
      this.rankgameList[this.indexs].content = convertTo2DArray(data, 2)
      this.$nextTick(() => {
        this.tabSwiper()
      })
    },
    tabSwiper() {
      if (!this.swiperRecord.includes(this.indexs)) {
        let eachSwiper = new Swiper(`.each_swiper${this.indexs}`, {
          centeredSlides: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: "my-bullet4",
            // clickableClass: "my-pagination-clickable",
          },
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            touchEnd: function (swiper, event) {
              // console.log("出发蓝");
            },
          },
        });
        this.rankSwiperList.push(eachSwiper);
      }
    },
    activateNav(index) {
      this.indexs = index;
      // this.isShow = true;
      this.rankgameList.forEach((item) => {
        item.show = false;
      });
      this.rankgameList[index].show = true;

      this.swiper.slideToLoop(index, 200, false);
      this.$nextTick(() => {
        this.getList()
      })
    },
    rankSwiper() {
      this.swiper = new Swiper(".mySwiper5", {
        centeredSlides: true,
        // initialSlide: index,
        noSwiping: true,
        initialSlide: this.indexs,
        noSwipingClass: "stop-swiping",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });
    },
  },
};
</script>
<style>
.mySwiper5 .my-bullet+.my-bullet {
  margin-left: 0.0625rem;
}

.mySwiper5 .my-bullet4 {
  display: inline-block;
  width: 0.24rem;
  height: 0.24rem;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 0.2rem;
}

.mySwiper5 .swiper-pagination-bullet-active {
  background-color: #cc5341 !important;
}

.mySwiper5 .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -1rem;
  left: 0%;
}
</style>
<style lang="scss" scoped>
.active {
  background: #cc5341;
  color: #f8f3e9 !important;
  border-radius: 0.266667rem
    /* 10/37.5 */
  ;
}

.rankGame_box {
  width: 100%;
  height: 100%;
  // background: pink;
  background: url(../../assets/image/wap/bg2/4.png) no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;

  .rankGame_title {
    color: #f8f3e9;
    font-weight: bold;
    font-size: 0.433333rem
      /* 20/37.5 */
    ;
    padding-top: 1.83333rem;
    padding-bottom: 0.33333rem;
    // flex: 0.2;
  }

  .rankGame_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    // height: 16rem;
    .nav_box {
      width: 8.933333rem
        /* 335/37.5 */
      ;
      height: 0.72rem
        /* 27/37.5 */
      ;
      background: #f6f5f4;
      border-radius: 0.266667rem
        /* 10/37.5 */
      ;
      display: flex;
      flex: 0.1;
      align-items: center;
      justify-content: center;
      // margin-bottom: .4rem /* 15/37.5 */;

      .nav_item {
        width: 2.99rem
          /* 111/37.5 */
        ;
        height: 0.72rem
          /* 27/37.5 */
        ;
        font-size: 0.426667rem
          /* 16/37.5 */
        ;
        color: #949494;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav_img {
          width: 0.293333rem
            /* 11/37.5 */
          ;
          // height: 0.4rem /* 15/37.5 */;
          margin-right: 0.133333rem
            /* 5/37.5 */
          ;

          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 0;
            // margin-right: 0.133333rem /* 5/37.5 */;
          }
        }
      }
    }

    .content_box {
      width: 1.744792rem/* 335/192 */;
      height: 14.6rem
        /* 520/37.5 */
      ;
      // margin-top: 0.6rem;
      display: flex;
      flex: 0.9;
        .content_img {
          width: 100%;
          height:5.546667rem /* 208/37.5 */ /* 182/37.5 */;
          border-radius: 0.266667rem /* 10/37.5 */ 0.266667rem /* 10/37.5 */ 0 0;
          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 0.266667rem /* 10/37.5 */ 0.266667rem /* 10/37.5 */;
          }
        }
    }
  }
}

.swiper {
  width: 100%;
  // height: 14rem;
  height: 100%;
}

.swiper-slide2>div:nth-child(2) {
  // margin-top: 0.2rem /* 15/37.5 */;
}

.swiper-slide {
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;

  // height: 14.5rem /* 565/192 */;
  .item_box {
    width: 8.933333rem/* 335/37.5 */;
    margin-top: 0.35rem
      /* 15/37.5 */
    ;
  }

  .head {
    width: 8.933333rem/* 335/37.5 */;
    height: 0.88rem/* 33/37.5 */;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 0.266667rem
      /* 10/37.5 */
      0.266667rem
      /* 10/37.5 */
    ;
    padding: 0 0.266667rem
      /* 10/37.5 */
    ;
    box-sizing: border-box;

    .title {
      color: #393333;
      font-size: 0.373333rem /* 14/37.5 */;
    }

    .tj {
      font-size: 0.32rem /* 12/37.5 */ ;
      color: #cc5341;
    }
  }
}

.swiper-slide img {
  display: block;
  width: 8.933333rem
    /* 335/37.5 */
  ;
  // height: 4.853333rem /* 182/37.5 */;
  height: auto;
  border-radius: 0.266667rem
    /* 10/37.5 */
    0.266667rem
    /* 10/37.5 */
    0 0;
}

::v-deep .swiper-pagination-bullet-active {
  background-color: #cc5341 !important;
}

::v-deep .swiper-pagination-bullet {
  margin-right: 0.533333rem
    /* 20/37.5 */
  ;
  background-color: #fff;
  opacity: 1;
  bottom: 1rem;
}

::v-deep .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: .426667rem /* 16/37.5 */ !important;
}

.empty_img {
  position: absolute;
  top: 0;
  left: 0;
  // height: 3.127953rem /* 581.367/192 */;
  // width: 6.328125rem /* 1215/192 */;

  position: relative;

  img {
    width: 6.328125rem;
    height: auto;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // width: 57%;
    // height: 140%;
  }
}
</style>
