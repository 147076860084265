import request from '@/api/backend/universal.js'
/**
 * 官网bannber
 * type 1外部链接 2自定义内容 3内部链接
 */
export function getBanner(query) {
    return request.get('/index/getBanner', { params: query });
}
/**
 * 官网游戏列表
 * scene 场景:展示场景: 1=热门游戏,2=新游推荐,3=排行管理
 * rank (scene=3的时候传递)排行类型:1=推荐榜, 2=新游榜, 3=人气榜
 */
export function engineGame(query) {
    return request.get('/index/officeGame', { params: query });
}
/**
 * 官网文章列表
 *  cate 1=公告,2=攻略,3=活动 (不填是全部)
 */
export function officeArticle(query) {
    return request.get('/index/officeArticle', { params: query });
}

