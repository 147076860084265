<template>
  <div id="newCenter " class="original">
    <div class="newCenter_box max-width">
      <div class="new_title">资讯中心</div>
      <div class="new_center_box">
        <div class="new_menu">
          <div class="new_menu_ul">
            <div v-for="(item, index) in menuList" :key="index" @click="changeNav(index)" class="menu_item"
              :class="indexs == index ? 'active' : ''">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="swiper newCenter_swiper">
          <div class="swiper-wrapper">
            <!-- 对应导航的小模块 -->
            <div class="new_content swiper swiper-slide stop-swiping" :class="`game_swiper_public${index}`"
              v-for="(item, index) in menuList" :key="index">
              <!-- 对应导航模块下面的list -->
              <div class="swiper-wrapper">
                <div class="item_content_box swiper-slide" v-for="(each, eachIndex) in item.list" :key="eachIndex">
                  <!-- 对应list下面的每一块内容 -->
                  <div @click="tolink(present)" class="hand" v-for="(present, presentIndex) in each">
                    <div class="item_title">{{ present.name }}</div>
                    <div class="item_content">
                      <div class="item_lef">
                        <img :src="present.img" alt="" />
                      </div>
                      <div class="item_rig">
                        <div class="item_info">{{ present.desc }}</div>

                        <div class="item_foot">
                          <div class="item_time">{{ present.show_time }}</div>
                          <div class="item_btn">
                            <div class="btn_tex">查看更多</div>
                            <div class="btn_img">
                              <img src="../../assets/image/icon/27.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="empty_img" v-if="!item.list || item.list.length === 0">
                <div class="empty_img">
                  <img src="../../assets/image/pc/bg/0.png" alt="" />
                </div>
                <div class="empty_tex">暂无内容</div>
              </div>
              <div class="swiper-pagination" v-if="item.list.length > 1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { customText } from '@/utils/key'
import { downloadA } from "@/utils/download";
import { officeArticle } from "@/api/init";
import { convertTo2DArray } from "@/utils/index";
export default {
  data() {
    return {
      indexs: 0,
      isShow: false,
      menuList: [
        {
          title: "全部资讯",
          show: true,
          list: [],
        },
        {
          title: "公告",
          show: false,
          list: [],
        },
        {
          title: "攻略",
          show: false,
          list: [],
        },
        {
          title: "活动",
          show: false,
          list: [],
        },
      ],
      gameSwiper: null, // 主体swiper
      gameSwiperList: [],
      queryList: [
        { cate: '' },
        { cate: 1 },
        { cate: 2 },
        { cate: 3 },
      ],
      swiperRecord: [],
    };
  },
  created() {

  },
  mounted() {
    this.getList();
    this.initSwiper();
  },
  methods: {
    tolink(item) {

      switch (item.type) {
        case 1:
          downloadA(item.url)
          break;
        case 2:
          if (item.content) {
            localStorage.setItem(customText, item.content);
            let href = window.location.href
            window.open(`${href}custom`)
          }
          break;
        case 3:
          if (item.url) {
            this.$router.push({
              path: item.url,
            })
          }
          break;
        default:
          break;
      }
    },
    async getList() {
      let { data, code } = await officeArticle(this.queryList[this.indexs]);
      this.menuList[this.indexs].list = convertTo2DArray(data, 2)
      this.$nextTick(() => {
        this.tabSwiper()
      })
    },
    changeNav(index) {
      console.log(index, "index");
      this.indexs = index;

      this.menuList.forEach((item) => {
        item.show = false;
      });
      this.menuList[index].show = true;
      // let slide_box = document.querySelector(".slide_box");
      // // slide_box.style.
      // slide_box.style.transitionDuration = "1s";
      // slide_box.style.transform = `translate3d(-${index}00%, 0px, 0px)`;

      this.gameSwiper.slideToLoop(index, 1000, false);
      this.$nextTick(() => {
        this.getList()
      })
    },
    tabSwiper() {
      if (!this.swiperRecord.includes(this.indexs)) {
        this.swiperRecord.push(this.indexs)
        let swiper = new Swiper(`.game_swiper_public${this.indexs}`, {
          centeredSlides: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: "my-bullet",
            clickableClass: "my-pagination-clickable",
          },
          // autoplay: {
          //   delay: 3000,
          //   disableOnInteraction: false,
          // },
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            touchEnd: function (swiper, event) {
              console.log("出发蓝");
            },
          },
        });

        this.gameSwiperList.push(swiper);
      }
    },
    // 初始化swiper
    initSwiper() {
      this.gameSwiper = new Swiper(".newCenter_swiper", {
        centeredSlides: true,
        // initialSlide: index,
        noSwiping: true,
        noSwipingClass: "stop-swiping",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });

      // this.gameSwiperList = [];
      // this.menuList.forEach((item, index) => {

      // });
    },
  },
};
</script>

<style scoped lang="scss">
.hand {
  cursor: pointer;
}

.max-width {
  max-width: 1920px;
  margin: 0 auto;
}

.original {
  height: 3.92rem;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.newCenter_box {
  height: 100%;
  background: url(../../assets/image/pc/bg/150.jpg) no-repeat 50%;

  // background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  .new_title {
    // width: 0.833333rem /* 160/192 */;
    height: 0.208333rem
      /* 40/192 */
    ;
    font-size: 0.208333rem
      /* 40/192 */
    ;
    color: #231c0e;
    font-weight: bold;
    margin-top: 0.390625rem
      /* 75/192 */
    ;
  }

  //内容
  .new_center_box {
    width: 6.3125rem
      /* 1212/192 */
    ;
    height: 2.79375rem
      /* 498/192 */
    ;
    background: #ffffff;
    border-radius: 0.052083rem
      /* 10/192 */
    ;
    opacity: 1;
    margin-top: 0.3125rem
      /* 60/192 */
    ;
    box-sizing: border-box;

    .new_menu {
      width: 6.041667rem
        /* 1160/192 */
      ;
      margin: 0 auto;
      border-bottom: 0.020833rem
        /* 4/192 */
        solid #f3f3f3;
      // background: palegoldenrod;
      text-align: center;
      line-height: 0.375rem;
      color: #000000;
      font-weight: bold;
      font-size: 24px;

      .new_menu_ul {
        display: flex;
        // align-items: center;
        // justify-content: space-evenly;
        position: relative;
        margin-top: 0.061458rem
          /* 31/192 */
        ;

        .menu_item {
          width: 1.510417rem
            /* 290/192 */
          ;
          height: 0.375rem
            /* 72/192 */
          ;
          text-align: center;
          // margin-left: 0.641667rem;
          cursor: pointer;
        }

        .active {
          // color: red;
          position: relative;
        }

        .active::after {
          content: "";
          position: absolute;
          left: 30%;
          bottom: -0.02rem
            /* 17/192 */
          ;
          width: 40%;
          height: 0.020833rem
            /* 4/192 */
          ;
          background: #e14b4b;
          border-radius: 0.026042rem
            /* 5/192 */
          ;
        }
      }
    }

    .new_content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 0.3125rem
        /* 60/192 */
      ;

      .item_content_box {
        padding: 0.161458rem
          /* 31/192 */
          0.182292rem
          /* 35/192 */
          0.203125rem
          /* 39/192 */
          0.135417rem
          /* 26/192 */
        ;
        box-sizing: border-box;

        .item_title {
          font-size: 0.09375rem
            /* 18/192 */
          ;
          color: #393333;
          font-weight: bold;
          cursor: pointer;
          margin-bottom: 0.083333rem
            /* 16/192 */
          ;
        }

        .item_title:hover {
          color: #cc5341;
        }

        .item_content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 0.677083rem
            /* 130/192 */
          ;
          margin-bottom: 0.166667rem
            /* 32/192 */
          ;
          width: 6.015625rem
            /* 1155/192 */
          ;

          .item_lef {
            width: 1.3125rem
              /* 252/192 */
            ;
            height: 0.677083rem
              /* 130/192 */
            ;
            border-radius: 0.052083rem
              /* 10/192 */
            ;

            margin-right: 0.104167rem
              /* 20/192 */
            ;
            cursor: pointer;
            overflow: hidden;

            img {
              // width: 1.3125rem /* 252/192 */;
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 0.052083rem
                /* 10/192 */
              ;
              transition: transform 0.3s ease;
              // height: 0.677083rem;
            }

            img:hover {
              transform: scale(1.2);
              /* 鼠标悬停时放大图片 */
            }
          }

          .item_rig {
            display: flex;
            // align-items: center;
            // justify-content: center;
            flex-direction: column;
            width: 4.598958rem
              /* 883/192 */
            ;

            .item_info {
              width: 4.598958rem
                /* 883/192 */
              ;
              max-height: 0.286458rem
                /* 55/192 */
              ;
              display: flex;
              flex-wrap: wrap;
              font-size: 0.083333rem
                /* 16/192 */
              ;
              color: #393333;
              // margin-bottom: 0.140625rem /* 27/192 */;
              line-height: 0.15rem
                /* 44/192 */
              ;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // display: -webkit-box;
              // -webkit-line-clamp: 2;
              /* 设置显示的行数 */
              // -webkit-box-orient: vertical;
            }

            .item_foot {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              // width: 4.967708rem /* 877/192 */;
              height: 0.208333rem
                /* 40/192 */
              ;
              margin-top: 0.145833rem
                /* 28/192 */
              ;

              .item_time {
                color: #949494;
                font-size: 0.083333rem
                  /* 16/192 */
                ;
              }

              .item_btn {
                width: 0.645833rem
                  /* 124/192 */
                ;
                border-radius: 0.052083rem
                  /* 10/192 */
                ;
                height: 0.208333rem
                  /* 40/192 */
                ;
                background: #cc5341;
                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;

                .btn_img {
                  width: 0.046875rem
                    /* 9/192 */
                  ;
                  height: 0.083333rem
                    /* 16/192 */
                  ;
                  margin-left: 0.036458rem
                    /* 7/192 */
                  ;

                  img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    margin-left: 0.036458rem
                      /* 7/192 */
                    ;
                  }
                }

                .btn_tex {
                  font-size: 0.083333rem
                    /* 16/192 */
                  ;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }

    .empty_img {
      height: 2.197917rem
        /* 422/192 */
      ;
      width: 100%
        /* 1215/192 */
      ;
      // margin-left: .68rem /* 50/192 */;
      // margin-top: -1rem;
      // margin: 0 auto;
      // background: #f6f5f4;
      // margin-bottom: .104167rem /* 20/192 */;
      margin-top: 0.020833rem
        /* 4/192 */
      ;
      border-radius: 0.052083rem
        /* 10/192 */
      ;
      position: relative;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 32%;
        height: 99%;
      }
    }
  }
}

// swiper
.newCenter_swiper {
  flex: 1;
  width: 100%;
  box-sizing: border-box;

  .new_content_box {
    width: 100%;
    height: 100%;
  }
}

.empty_tex {
  text-align: center;
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #949494;
}
</style>
<style>
.newCenter_swiper .my-bullet+.my-bullet {
  margin-left: 0.0625rem;
}

.newCenter_swiper .my-bullet {
  display: inline-block;
  width: 0.046875rem
    /* 9/192 */
  ;
  height: 0.046875rem
    /* 9/192 */
  ;
  border-radius: 50%;
  background-color: #949494;
}

.newCenter_swiper .swiper-pagination-bullet-active {
  background-color: #cc5341 !important;
}

.newCenter_swiper .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0.2rem;
  left: 0%;
}
</style>
