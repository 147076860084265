<template>
  <div id="hotGame">
    <div class="title">热门游戏</div>
    <div class="hotgame_content">
      <ul class="hotgame_list" v-for="item in hotGameListChunkone" @click="tolink(item)">
        <li class="hotgame_li">
          <div class="list_img">
            <img :src="item.img" alt />
          </div>
          <div class="list_content">
            <div class="title_box">{{ item.name }}</div>
            <div class="descibe_box">
              <div class="version">版本：{{ item.cate_name }}</div>
              <div class="newOpen">上线时间:{{ item.online_time }}</div>
              <div class="newOpen">最新开服时间：{{ item.open_time }}</div>
            </div>
            <div class="gw">官网：{{ item.url }}</div>
          </div>
        </li>
      </ul>
      <!--  -->
    </div>
    <div class="hotgame_content">
      <ul class="hotgame_list" v-for="twoItem in hotGameListChunktwo">
        <li class="hotgame_li">
          <div class="list_img">
            <img :src="twoItem.img" alt />
          </div>
          <div class="list_content">
            <div class="title_box">{{ twoItem.name }}</div>
            <div class="descibe_box">
              <div class="version">版本：{{ twoItem.cate_name }}</div>
              <div class="newOpen">上线时间:{{ twoItem.online_time }}</div>
              <div class="newOpen">最新开服时间：{{ twoItem.open_time }}</div>
            </div>
            <div class="gw">官网：{{ twoItem.url }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {slicing2DArray } from "@/utils/index";
import { mapState } from 'vuex';
import { customText } from '@/utils/key'
import { downloadA } from "@/utils/download";
export default {
  data() {
    return {
      hotGameListChunkone:[],
      hotGameListChunktwo:[]
    };
  },

  mounted() { },
  computed: {
    ...mapState({
      hotGameList: (state) => state.init.hotGameList,
    }),
  },
  watch:{
    hotGameList:{
      handler(val) {
        this.hotGameListChunkone = slicing2DArray(val)[0]
        this.hotGameListChunktwo = slicing2DArray(val)[1]
      },
      deep: true,
      immediate: true,
    },
  },
  created() { },
  methods: {
    tolink(item) {
      
      switch (item.type) {
        case 1:
          downloadA(item.url)
          break;
        case 2:
          if (item.content) {
            localStorage.setItem(customText, item.content);
            let href = window.location.href
            window.open(`${href}custom`)
          }
          break;
        case 3:
          if (item.url) {
            this.$router.push({
              path: item.url,
            })
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;

  color: #949494;
}

#hotGame {
  width: 100%;
  height: 100%;
  background: url(../../assets/image/wap/bg2/2.png) no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  .title {
    font-weight: bold;
    font-size: 0.533333rem
      /* 20/37.5 */
    ;
    color: #f8f3e9;
    padding-top: 1.83333rem
      /* 20/37.5 */
    ;
    padding-bottom: 0.533333rem
      /* 20/37.5 */
    ;
  }

  .hotgame_content {
    width: 97%;
    height: 7.24rem
      /* 534/37.5 */
    ;
    display: flex;
    overflow-x: auto;
    margin-right: 0.353333rem
      /* 17/37.5 */
    ;
    // margin-bottom: 0.8rem /* 30/37.5 */;
    box-sizing: border-box;

    .hotgame_list {
      flex-shrink: 0;

      .hotgame_li {
        flex-basis: calc(25% - 10px);
        /* 4个元素一行，减去间距的宽度 */
        height: 6.72rem
          /* 252/37.5 */
        ;
        border-radius: 0.266667rem
          /* 10/37.5 */
        ;
        margin-left: 0.3rem;
        box-sizing: border-box;

        .list_img {
          width: 5.413333rem /* 203/37.5 */ ;
          height: auto;
          height: 4.026667rem /* 151/37.5 */;
          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 0.266667rem 0.266667rem 0 0
              /* 10/37.5 */
            ;
          }
        }

        .list_content {
          width: 5.413333rem
            /* 203/37.5 */
          ;
          // height:3.5rem;
          box-sizing: border-box;
          line-height: 0.53333rem;
          background: #f6f5f4;
          text-align: left;
          //   margin-right: 0.266667rem /* 10/37.5 */;
          box-sizing: border-box;
          padding-top: 0.12rem
            /* 10/37.5 */
          ;
          padding-bottom: 0.12rem
            /* 10/37.5 */
          ;
          border-radius: 0 0 0.266667rem 0.266667rem
            /* 10/37.5 */
          ;

          .title_box {
            color: #393333;
            font-size: 0.326667rem
              /* 16/37.5 */
            ;
            font-weight: bold;
            padding-left: 0.266667rem
              /* 10/37.5 */
            ;
            // padding-bottom: .266667rem /* 10/37.5 */;
          }

          .descibe_box {
            font-size: 0.32rem
              /* 12/37.5 */
            ;
            color: #949494;
            padding-left: 0.266667rem
              /* 10/37.5 */
            ;
          }

          .gw {
            font-size: 0.32rem
              /* 12/37.5 */
            ;
            color: #949494;
            padding-left: 0.266667rem;
            font-size: 0.26rem;
            /* 10/37.5 */
          }
        }
      }
    }
  }
}
</style>
