<template>
  <div id="newGame" class="original">
    <div class="newGame max-width">
      <div class="newGame_title">新游推荐</div>
      <!-- swiper -->
      <div class="case_box" id="box">
        <div class="ImageBox">
          <el-carousel
            :autoplay="false"
            ref="carousel"
            indicator-position="none"
            :interval="3000"
            type="card"
            :height="500 / 80 + 'rem'"
          >
            <el-carousel-item v-for="(item, index) in newGameList" :key="index">
              <div class="box_body">
                <div class="ImageBox_slideshow">
                  <img :src="item.img" alt="千年游戏盒子" />
                </div>
                <!-- <div class="ImageBox_info">{{ item.name }}</div> -->
              </div>
            </el-carousel-item>
          </el-carousel>
          <!-- 前进后退按钮 -->
          <div class="LeftButton" @click="previous('prev')"></div>
          <div class="RightButton" @click="next"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel } from "element-ui";
import { mapState } from 'vuex';
export default {
  components: {
    "el-carousel": Carousel,
  },
  data() {
    return {
      bannerHeight: 0, //图片父容器的高度
      screenWidth: 3840, //屏幕的宽度
    };
  },
  created() {},
  mounted() {
    this.screenWidth = window.innerWidth;
    this.setSize();
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.setSize;
    };
  },
  computed: {
    ...mapState({
      newGameList: (state) => state.init.newGameList,
    })
  },
  methods: {
    // 合作案例 上一页
    previous(value) {
      this.$refs.carousel.prev();
      this.leftButton = true;
      this.rightButton = false;
    },
    // 合作案例 下一页
    next() {
      this.$refs.carousel.next();
      this.leftButton = false;
      this.rightButton = true;
    },
    setSize: function () {
      // 通过屏幕宽度(图片宽度)计算高度
      this.bannerHeight = (426 / 1920) * this.screenWidth;
    },
  },
};
</script>

<style scoped lang="scss">
.max-width {
  max-width: 1920px;
  margin: 0 auto;
}
.original {
  height: 3.5rem /* 671/192 */;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.newGame {
  height: 100%;

  background: url(../../assets/image/pc/bg/143.jpg) no-repeat 50%;
  // background: pink;
  background-size: cover;

  .newGame_title {
    // margin-top: 0.395833rem /* 76/192 */;
    width: 100% /* 160/192 */;
    height: 0.916667rem /* 176/192 */;
    text-align: center;
    line-height: 1.1rem /* 176/192 */;
    // background: palegreen;
    font-size: 0.208333rem /* 40/192 */;
    color: #000000;
    font-weight: bold;
  }
  // .case_box {
  //   // margin-top: 1.65rem /* 132/80 */;
  //   position: relative;
  //   height: 2.261458rem /* 415/192 */;
  //   overflow-y: hidden;
  //   .ImageBox {
  //     width: 5.6875rem /* 1092/192 */;
  //     margin: 0 auto;
  //     position: relative;
  //     .RightButton {
  //       position: absolute;
  //       right: -0.25625rem /* 30/192 */;
  //       top: 40%;
  //       transform: translateY(-34%);
  //       width: 0.15625rem /* 30/192 */;
  //       height: 0.229167rem /* 44/192 */;
  //       // background: #000000;
  //       cursor: pointer;
  //       background: url("../../assets/image/icon/40.png") no-repeat center;
  //       background-size: contain;
  //     }
  //     .RightButton:hover {
  //       background: url("../../assets/image/icon/h40.png") no-repeat center;
  //       background-size: contain;
  //     }
  //     .LeftButton {
  //       position: absolute;
  //       left: -0.25625rem;
  //       top: 40%;
  //       transform: translateY(-34%);
  //       width: 0.15625rem /* 30/192 */;
  //       height: 0.229167rem /* 44/192 */;
  //       cursor: pointer;
  //       // background: #000000;
  //       background: url("../../assets/image/icon/41.png") no-repeat center;
  //       background-size: contain;
  //     }

  //     .LeftButton:hover {
  //       background: url("../../assets/image/icon/h41.png") no-repeat center;
  //       background-size: contain;
  //     }
  //     ::v-deep .el-carousel__mask {
  //      display: none !important;
  //       /* background-color: rgba(0, 0, 0, 0.35); */
  //     }
  //     ::v-deep .el-carousel__arrow--left {
  //       display: none !important;
  //     }

  //     ::v-deep .el-carousel__arrow--right {
  //       display: none !important;
  //     }
  //     .ImageBox_info {
  //       // padding: 0.1625rem /* 13/80 */ 0 0.1375rem /* 27/80 */ 0.2625rem
  //       //   /* 21/80 */;
  //       color: #000000;
  //       font-size: .135417rem /* 26/192 */;
  //       height: 0.338542rem /* 65/192 */;
  //       text-align: center;
  //       line-height: 0.338542rem /* 65/192 */;
  //     }
  //     .ImageBox_slideshow {
  //       height: 2.942708rem /* 565/192 */;
  //       // height: 1.822917rem /* 350/192 */;
  //       width: 100%;
  //       height: 100%;
  //       border-radius: 0.125rem /* 10/80 */ 0.125rem /* 10/80 */ 0px 0px;
  //       overflow: hidden;
  //       position: relative;
  //       img {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }
  //   }
  // }

  //   .box_body {
  //     margin: 0 auto;
  //     width: 88%;
  //     // height: 88%;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content: center;
  //     background-color: #fff;
  //     border-radius: 0.125rem /* 10/80 */;
  //     box-shadow: rgba(0, 0, 0, 0.1) 0px .05rem /* 4/80 */ .15rem /* 12/80 */;
  //   }

  //   .case_box .leftButton_bg {
  //     background: url("../../assets/image/icon/41.png") no-repeat center;
  //     background-size: cover;
  //   }

  //   .case_box .leftButton_bg1 {
  //     // background: url("../images/PC/prevButton1.png")no-repeat center;
  //     // background-size: cover;
  //   }

  //   .case_box .rightButton_bg {
  //     background: url("../../assets/image/icon/40.png") no-repeat center;
  //     background-size: cover;
  //   }

  //   .case_box .rightButton_bg1 {
  //     // background: url("../images/PC/nextButton1.png")no-repeat center;
  //     // background-size: cover;
  //   }
  // }
  // .el-carousel__item,
  // .el-carousel__mask {
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   background-color: unset !important;
  // }
  // // ::v-deep .is-in-stage{
  // //   transform: translateX(-26.435px) scale(1) !important;
}
.case_box {
  // margin-top: 1.65rem /* 132/80 */;
  position: relative;
  height: 2.161458rem /* 415/192 */ /* 397/80 */;
}

.ImageBox {
  // width: 6.125rem /* 970/80 */;
  width: 5.6875rem /* 1092/192 */;
  // width: 5.859375rem /* 1125/192 */;
  margin: 0 auto;
  position: relative;
  text-align: center;
  line-height: 0.35rem;
}

.ImageBox .el-carousel__mask {
  opacity: 0;
  /* background-color: rgba(0, 0, 0, 0.35); */
}

::v-deep .ImageBox .el-carousel__arrow--left {
  display: none !important;
}

::v-deep .ImageBox .el-carousel__arrow--right {
  display: none !important;
}

.ImageBox .ImageBox_info {
  // padding: 0.1625rem /* 13/80 */ 0 0.3375rem /* 27/80 */ 0.2625rem /* 21/80 */;
  width: 100%;
  color: #000000;
  font-size: 0.135417rem /* 26/192 */;
  background: #fff;
  height: 0.338542rem /* 65/192 */;
  // border-radius:0 0  .052083rem /* 10/192 */ .052083rem /* 10/192 */;
  border-radius: 0.052083rem /* 10/192 */;
}

.ImageBox .ImageBox_slideshow {
  // height: 3.5625rem /* 285/80 */;
  height: 1.484375rem /* 285/192 */;
  // height: .75rem /* 300/80 */;
  border-radius: 0.125rem /* 10/80 */;
  overflow: hidden;
  position: relative;
  left: 0.01rem;
  img {
    width: 100%;
    height: 100%;
    border-radius: 0.125rem /* 10/80 */;
  }
}
::v-deep .el-carousel__mask {
  display: none !important;
}
.box_body {
  margin: 0 auto;
  width: 100%;
  height: 81%;
  // background-color: #fff;
  border-radius: 0.052083rem /* 10/192 */;
  // box-shadow:0 0.2rem 0.02rem 0.3rem rgba(0, 0, 0, 0.1);
}

.ImageBox .is-in-stage {
  height: 1.8625rem /* 300/192 */;
  border-radius: 0.052083rem /* 10/192 */;
}
::v-deep .el-carousel__container {
  height: 2.25rem !important;
}
.ImageBox .is-in-stage .ImageBox_slideshow::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, #000, #000);
  opacity: 0.6;
}

.ImageBox .is-active .ImageBox_slideshow::after {
  content: "";
  display: none;
}

.ImageBox .is-active {
  height: 2.161458rem /* 415/192 */;
}

.ImageBox .is-active .box_body {
  margin: 0 auto;
  width: 100%;
  height: 81%;
  // background-color: #fff;
  border-radius: 0.052083rem /* 10/192 */;
  // box-shadow:0 0.2rem 0.02rem 0.3rem rgba(0, 0, 0, 0.01);
}

.ImageBox .is-active .ImageBox_slideshow {
  height: 1.822917rem; /* 350/192 */
}

.ImageBox .ImageBox_slideshow img {
  display: block;
  width: 100%;
  height: 100%;
}

.el-carousel__indicators--outside {
  display: none;
}

.case_box .RightButton {
  position: absolute;
  right: -0.825rem /* 66/80 */;
  top: 30%;
  transform: translateY(-34%);
  width: 0.7375rem /* 59/80 */;
  height: 1.8375rem /* 147/80 */;
  cursor: pointer;
}

.case_box .LeftButton {
  position: absolute;
  left: -0.25625rem;
  top: 38%;
  transform: translateY(-34%);
  width: 0.15625rem /* 30/192 */;
  height: 0.229167rem /* 44/192 */;
  cursor: pointer;
  // background: #000000;
  background: url("../../assets/image/icon/41.png") no-repeat center;
  background-size: contain;
}
.case_box .RightButton {
  position: absolute;
  right: -0.25625rem;
  top: 38%;
  transform: translateY(-34%);
  width: 0.15625rem /* 30/192 */;
  height: 0.229167rem /* 44/192 */;
  // background: #000000;
  cursor: pointer;
  background: url("../../assets/image/icon/40.png") no-repeat center;
  background-size: contain;
}
.case_box .LeftButton:hover {
  background: url("../../assets/image/icon/h41.png") no-repeat center;
  background-size: contain;
}
.case_box .RightButton:hover {
  background: url("../../assets/image/icon/h40.png") no-repeat center;
  background-size: contain;
}
// .case_box .leftButton_bg {
// 	background: url("../images/PC/prevButton.png")no-repeat center;
// 	background-size: cover;
// }

// .case_box .leftButton_bg1 {
// 	background: url("../images/PC/prevButton1.png")no-repeat center;
// 	background-size: cover;
// }

// .case_box .rightButton_bg {
// 	background: url("../images/PC/nextButton.png")no-repeat center;
// 	background-size: cover;
// }

// .case_box .rightButton_bg1 {
// 	background: url("../images/PC/nextButton1.png")no-repeat center;
// 	background-size: cover;
// }
</style>
