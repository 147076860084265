<template>
  <div id="newGame">
    <div class="newGame_title">新游推荐</div>
    <!-- swiper -->
    <div class="case_box" id="box">
      <div class="ImageBox">
        <el-carousel
          :autoplay="true"
          ref="carousel"
          indicator-position="none"
          :interval="3000"
          type="card"
          @change="carIndex"
          height="426px"
          arrow="never"
        >
          <el-carousel-item v-for="(item, index) in newGameList" :key="index">
            <div class="box_body">
              <div class="ImageBox_slideshow">
                <img :src="item.phone_img" alt="千年游戏盒子" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- <div class="ImageBox_info">
          <div class="content">{{ newGameList[currentIndex].desc }}</div>
          <div class="time">{{ newGameList[currentIndex].open_time }}</div>
        </div> -->
        <!-- 前进后退按钮 -->
        <div class="LeftButton" @click="previous('prev')"></div>
        <div class="RightButton" @click="next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Carousel } from "element-ui";
export default {
  components: {
    "el-carousel": Carousel,
  },
  data() {
    return {
      currentIndex: 0,
      isShow: false,
    };
  },
  mounted() {
    this.slideBanner();
  },
  computed: {
    ...mapState({
      newGameList: (state) => state.init.newGameList,
    })
  },
  methods: {
    startAuto() {
      if (this.autoplay == false) {
        this.autoplay = true;
      }
    },
    stopAuto() {
      if (this.autoplay == true) {
        this.autoplay = false;
      }
    },
    slideBanner() {
      //选中item的盒子
      var box = document.querySelector(".el-carousel__container");
      //手指起点X坐标
      var startPoint = 0;
      //手指滑动重点X坐标
      var stopPoint = 0;

      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      let that = this;
      //手指按下
      box.addEventListener("touchstart", function (e) {
        // //手指按下的时候停止自动轮播
        that.stopAuto();
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离

      box.addEventListener("touchend", function (e) {
        // console.log("1：" + startPoint);
        // console.log("2：" + stopPoint);
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.carousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.carousel.prev();
          return;
        }
      });
    },

    carIndex(e) {
      this.currentIndex = e;
    },
    // 合作案例 上一页
    previous(value) {
      this.$refs.carousel.prev();
      this.leftButton = true;
      this.rightButton = false;
    },
    // 合作案例 下一页
    next() {
      this.$refs.carousel.next();
      this.leftButton = false;
      this.rightButton = true;
    },
  },
};
</script>
<style></style>
<style scoped lang="scss">
#newGame {
  width: 100%;
  height: 100%;
  background: url(../../assets/image/wap/bg2/3.png) no-repeat;
  // background: pink;
  background-size: cover;
  text-align: center;
  .newGame_title {
    // margin-top: 0.395833rem /* 76/192 */
    // background: palegreen;
    font-size: 0.533333rem /* 20/37.5 */;
    color: #000000;
    font-weight: bold;
    padding-top: 1.83333rem;
    padding-bottom: 0.533333rem;
  }
  .case_box {
    // margin-top: 1.65rem /* 132/80 */
    position: relative;
    height: 100%;
    overflow-y: hidden;
    .ImageBox {
      width: 7.573333rem /* 284/37.5 */;
      margin: 0 auto;
      position: relative;
      .el-carousel__mask {
        opacity: 0;
        /* background-color: rgba(0, 0, 0, 0.35); */
      }
      ::v-deep .el-carousel__arrow--left {
        display: none !important;
      }

      ::v-deep .el-carousel__arrow--right {
        display: none !important;
      }

      .ImageBox_info {
        // padding: 0.1625rem /* 13/80 */ 0 0.1375rem /* 27/80 */ 0.2625rem
        //   /* 21/80 */;
        margin-top: 0.533333rem /* 20/37.5 */;
        .content {
          font-size: 0.426667rem /* 16/37.5 */;
          color: #393333;
          font-weight: bold;
        }
        .time {
          font-size: 0.32rem /* 12/37.5 */;
          color: #231c0e;
          margin-top: 0.24rem /* 9/37.5 */;
        }
      }
      .ImageBox_slideshow {
        // width: 19.146667rem /* 718/37.5 */;
        height: 11.493333rem /* 431/37.5 */;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        img {
          width: 100%;
          display: block;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
  .box_body {
    margin: 0 auto;
    // width: 6.746667rem /* 253/37.5 */;
    height: 11.36rem /* 426/37.5 */;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.266667rem /* 10/37.5 */;
    box-shadow: 0px 0.125rem 0.3625rem 1px rgba(0, 0, 0, 0.13);
  }
  .RightButton {
    position: absolute;
    right: -0.86667rem /* 4/37.5 */;
    top: 40%;
    transform: translateY(-40%);
    width: 0.4rem /* 15/37.5 */;
    height: 0.746667rem /* 28/37.5 */;
    // background: #000000;
    cursor: pointer;
    background: url("../../assets/image/icon/40.png") no-repeat center;
    background-size: contain;
  }

  .LeftButton {
    position: absolute;
    left: -0.86667rem /* 4/37.5 */;
    top: 40%;
    transform: translateY(-40%);
    width: 0.4rem /* 15/37.5 */;
    height: 0.746667rem /* 28/37.5 */;
    cursor: pointer;
    // background: #000000;
    background: url("../../assets/image/icon/41.png") no-repeat center;
    background-size: contain;
  }
  .case_box .leftButton_bg {
    background: url("../../assets/image/wap/icon/3.png") no-repeat center;
    background-size: contain;
  }

  .case_box .leftButton_bg1 {
    // background: url("../images/PC/prevButton1.png")no-repeat center;
    // background-size: cover;
  }

  .case_box .rightButton_bg {
    background: url("../../assets/image/wap/icon/10.png") no-repeat center;
    background-size: contain;
  }

  .case_box .rightButton_bg1 {
    // background: url("../images/PC/nextButton1.png")no-repeat center;
    // background-size: cover;
  }
  ::v-deep .el-carousel__item--card {
    width: 66%;
    transition: transform 0.4s ease-in-out;
  }
  ::v-deep .el-carousel__item,
  .el-carousel__mask {
    height: 100%;
    position: absolute;
    top: 0;
    left: -1.25rem !important;
    width: 240px;
    border-radius: 10px;
  }
}
</style>
