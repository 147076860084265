<template>
  <div id="downloadGame" class="main">
    <div class="box">
      <div class="downloadGame_title">下载盒子</div>

      <div class="content">
        <div class="xz_content" @click="iosTo()">
          <div class="xz_img">
            <div class="xia_img">
              <img src="../../assets/image/wap/icon/11.png" alt />
            </div>
          </div>
          <div class="btn">下载ios版本</div>
          <div class="tex"></div>
        </div>
        <div class="xz_content" @click="azTo()" style="margin: 0 0.786458rem; /* 151/192 */">
          <div class="xz_img">
            <div class="xia_img">
              <img src="../../assets/image/wap/icon/12.png" alt />
            </div>
          </div>
          <div class="btn">下载Android版本</div>
        </div>
        <div class="xz_content" @click="PCTo">
          <div class="xz_img">
            <div class="xia_img" style="
                width: 1.36rem /* 51/37.5 */;
                height: 1.253333rem /* 47/37.5 */;
              ">
              <img src="../../assets/image/icon/pc.png" alt />
            </div>
          </div>
          <div class="btn">下载电脑版本</div>
        </div>
        <div class="xz_content" style="margin-right: 1.52rem; /* 57/37.5 */">
          <div class="xz_img">
            <div class="xia_img" style="width: 1.6rem /* 60/37.5 */; height: 1.6rem; /* 60/37.5 */">
              <img src="../../assets/image/icon/xz.jpg" alt />
            </div>
          </div>
          <div class="btn" style="width: 3rem">长按保存 扫码下载</div>
        </div>
        <div class="xz_content">
          <div class="xz_img">
            <div class="xia_img" style="width: 1.6rem /* 60/37.5 */; height: 1.6rem; /* 60/37.5 */">
              <img src="../../assets/image/icon/gzh.jpg" alt />
            </div>
          </div>
          <div class="btn" style="width: 3rem">关注微信公众号</div>
        </div>
        <div class="toast" v-if="isToast">即将震撼来袭 敬请期待</div>
      </div>
    </div>

    <div class="footer">
      <!-- <div class="texA">千年游戏盒子</div> -->
      <div class="tex tex1">主办单位名称：江阴千游网络科技有限公司</div>
      <div class="tex">App备案号：苏ICP备2022030317号-8</div>
      <div class="tex"> 违规投诉/举报邮箱：xiecheng1@3975.com</div>
      <div class="tex"> 违规投诉/举报邮箱：jiankongzu2024@163.com</div>
      <div class="tex" style="
          width: 7.3rem /* 274/192 */;
          height: 1.38rem;
          text-align: center;
          margin-top: 0.13rem;
          margin-bottom: 1.06rem;
        ">
        健康游戏忠告：抵制不良游戏 拒绝盗版游戏 注意自我保护 防受骗上当
        适度游戏益脑 迷游戏伤身 合理安排时间 享受健康生活
        <div>
          <router-link to="yinsi" style="color: white" target="_blank">《隐私政策》</router-link>
        </div>
      </div>
      <!-- <div class="tex">请您合理控制游戏时间，避免沉溺游戏影响生活</div>
      <div class="tex">注意自我保护，防范网络陷阱</div> -->
    </div>
  </div>
</template>

<script>
import { downloadA } from "@/utils/download";
import { qywx, WXTest, qq, iosModel } from "@/utils/guidance";
export default {
  data() {
    return {
      isToast: false,
    };
  },
  mounted() { },
  created() { },
  mounted() { },
  methods: {
    iosTo() {
      this.$emit("update", 0, true);
    },
    azTo() {
      this.$emit("update", 1, true);
    },
    PCTo() {
      // if (qywx || qq || WXTest) {
      this.$emit('update', 2, true)
      // return
      // }
      // downloadA(PC_URL, "_self")
      // this.isToast = true;
      // setTimeout(() => {
      //   this.isToast = false;
      // }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;

  flex-direction: column;
  box-sizing: border-box;
  background: url(../../assets/image/wap/bg2/6.png) no-repeat;
  background-size: cover;

  .toast {
    width: 6.125rem
      /* 50/16 */
    ;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.34rem;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    border-radius: 0.18rem;
    color: #ffffff;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
  }

  .box {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
  }

  .downloadGame_title {
    font-size: 0.533333rem
      /* 20/37.5 */
    ;
    font-weight: bold;
    color: #f8f3e9;
    padding-top: 1.83333rem;
    padding-bottom: 0.533333rem;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-wrap: wrap;

    .xz_content {
      display: flex;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;

      .xz_img {
        width: 2.346667rem
          /* 88/37.5 */
        ;
        height: 2.346667rem
          /* 88/37.5 */
        ;
        background: #3a2d24;
        border-radius: 0.266667rem
          /* 10/37.5 */
        ;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;

        .xia_img {
          width: 1.013333rem
            /* 38/37.5 */
          ;
          height: 1.253333rem
            /* 47/37.5 */
          ;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .btn {
        width: 2.346667rem
          /* 88/37.5 */
        ;
        height: 0.693333rem
          /* 26/37.5 */
        ;
        background: #cc5341;
        border-radius: 0.853333rem
          /* 32/37.5 */
        ;
        opacity: 1;
        font-size: 0.26rem
          /* 12/37.5 */
        ;

        color: #f8f3e9;
        margin-top: 0.266667rem
          /* 10/37.5 */
        ;
        margin-bottom: 0.32rem
          /* 12/37.5 */
        ;
        text-align: center;
        line-height: 0.693333rem;
        cursor: pointer;
      }

      .tex {
        font-size: 0.32rem
          /* 12/37.5 */
        ;
        color: #f8f3e9;
      }
    }
  }

  .footer {
    width: 100%;
    height: 5.5rem
      /* 182/37.5 */
    ;
    background: #231c0e;
    color: #ffffff;
    font-size: 0.32rem
      /* 12/37.5 */
    ;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    line-height:.533333rem /* 20/37.5 */;
    // position: absolute;
    // bottom: 0;
    .tex1 {
      margin-top: 0.5rem;
    }

    .texA {
      font-size: 0.48rem
        /* 18/37.5 */
      ;
    }
  }

  .yindao {
    display: none;
    width: 100%;
    height: 100%;
    background: url("../../assets/image/banner-yd.png") no-repeat center;
    background-size: 100% 100%;
    background-color: #000000;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
  }
}
</style>
