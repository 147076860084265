import { getBanner, engineGame } from '@/api/init'
export default {
    state: {
        bannarList: [],
        hotGameList: [],
        newGameList: [],
        // 专区游戏
        speciallyGameList: {
            list1: [],
            list2: [],
            list3: [],
            list4: [],
        },
    },

    mutations: {
        setBannerList(state, data) {
            state.bannarList = data
        },
        setHotGameList(state, data) {
            state.hotGameList = data
        },
        setNewGameList(state, data) {
            state.newGameList = data
        },
    },

    actions: {
        initActions({ dispatch }) {
            dispatch('getBannerList')
            dispatch('getHotGameList')
            dispatch('getNewGameList')
        },
        async getBannerList({ commit }) {
            let { code, data } = await getBanner({ scene: 1 })
            commit('setBannerList', data)

        },
        async getHotGameList({ commit }) {
            let { code, data } = await engineGame({ scene: 1 })
            commit('setHotGameList', data)
        },
        async getNewGameList({ commit }) {
            let { code, data } = await engineGame({ scene: 2 })
            commit('setNewGameList', data)
        },
    },
};
